import { css } from 'styled-components'

import UKNumberPlateEot from './assets/fonts/uknumberplate/UKNumberPlate.eot'
import UKNumberPlateWoff2 from './assets/fonts/uknumberplate/UKNumberPlate.woff2'
import volvoNovumMediumWoff2 from './assets/fonts/Volvo-Novum-Medium.woff2'
import volvoNovumSemiLightWoff2 from './assets/fonts/Volvo-Novum-SemiLight.woff2'

const fonts = css`
  @font-face {
    font-family: 'Volvo Novum';
    src: url('${volvoNovumSemiLightWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Volvo Novum';
    src: url('${volvoNovumMediumWoff2 as never}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateEot as never}') format('eot'),
      url('${UKNumberPlateEot as never}') format('eot');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateWoff2 as never}') format('woff2'),
      url('${UKNumberPlateWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`

export default fonts
