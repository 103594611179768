import { Icon, Typo, WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import xc40bevForeground from './assets/images/promo/XC40BEV/foreground.png'
import volvoSubscription from './assets/images/volvo-car-insurance-hero.jpg'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const volvoColors = {
  white: '#FFFFFF',
  primary600: '#2A609D',
  primary50: '#F4F7FA',
  gray900: '#000000EB',
  gray600: '#0000008F',
  gray400: '#D5D5D5',
  gray100: '#00000014',
  gray50: '#FAFAFA',
  alert600: '#BF2012',
  alert50: '#FAEDEC',
  success600: '#048220',
  success50: '#EBF6EE',
  warning600: '#EB7400',
  warning50: '#FDF4EB',
}

const typoSizes = {
  xxl: {
    fontSize: ['28px', '32px', '40px', '48px'],
    lineHeight: ['32px', '36px', '44px', '52px'],
  },
  xl: {
    fontSize: ['24px', '24px', '28px', '32px'],
    lineHeight: ['32px', '32px', '32px', '36px'],
  },
  lg: {
    fontSize: ['20px', '20px', '24px'],
    lineHeight: ['28px', '28px', '32px'],
  },
  md: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['26px', '26px', '28px'],
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '20px',
  },
}

export const volvoTheme: WriskTheme = {
  // Common
  name: 'Volvo',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  radii: ['0rem', '4px', '4px', '0rem', '0rem'],
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  fonts: {
    body: `'Volvo Novum', sans-serif`,
    header: `'Volvo Novum', sans-serif`,
    navbar: `'Volvo Novum', sans-serif`,
    buttons: `'Volvo Novum', sans-serif`,
    input: `'Volvo Novum', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  typoSizes,
  shadows: {
    default: `0 0 0 1px ${volvoColors.gray100}`,
    hover: `0 0 0 1px ${volvoColors.gray400}`,
    input: `0 0 0 1px ${volvoColors.primary600}`,
    inset: `inset -8px 0 0 0 ${volvoColors.white}, inset 8px 0 0 0 ${volvoColors.white}`,
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: `inset 0 0 0 1px ${volvoColors.gray100}`,
    radioStandalone: `inset 0 0 0 1px ${volvoColors.gray100}`,
    radioHover: `inset 0 0 0 1px ${volvoColors.gray900}`,
    radioChecked: `inset 0 0 0 2px ${volvoColors.primary600}`,
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['48px', '64px'],
  },
  colors: {
    chalk: volvoColors.white,
    background: volvoColors.white,
    body: volvoColors.gray900,
    bodySecondary: volvoColors.gray600,
    hyperlink: volvoColors.primary600,
    focus: volvoColors.gray900,
    hover: volvoColors.gray50,

    primaryAccent: volvoColors.primary600,
    secondaryAccent: volvoColors.primary600,

    foregroundPromoBanner: volvoColors.white,
    backgroundPromoBanner: volvoColors.gray900,

    foregroundPromoActivated: volvoColors.white,
    backgroundPromoActivated: volvoColors.success600,

    textOnNavigation: volvoColors.gray900,
    surfaceNavigation: volvoColors.gray50,

    textInfo: volvoColors.primary600,
    surfaceInfoSubdued: volvoColors.primary50,
    surfaceInfo: volvoColors.primary50,

    textCritical: volvoColors.alert600,
    textOnCritical: volvoColors.alert600,
    surfaceCritical: volvoColors.alert50,
    surfaceCriticalHovered: volvoColors.alert50,
    surfaceCriticalSubdued: volvoColors.alert50,

    textWarning: volvoColors.warning600,
    textOnWarning: volvoColors.warning600,
    surfaceWarning: volvoColors.warning50,
    surfaceWarningSubdued: volvoColors.warning50,

    textHighlight: volvoColors.gray600,
    textOnHighlight: volvoColors.gray600,
    surfaceHighlight: volvoColors.gray50,
    surfaceHighlightSubdued: volvoColors.gray50,

    textSuccess: volvoColors.success600,
    textOnSuccess: volvoColors.success600,
    surfaceSuccess: volvoColors.success50,
    surfaceSuccessSubdued: volvoColors.success50,

    border: volvoColors.gray100,
    divider: volvoColors.gray100,
    inactive: volvoColors.gray600,
    placeholder: volvoColors.gray600,

    textDisabled: volvoColors.gray600,
    surfaceDisabled: volvoColors.gray100,

    textFooter: volvoColors.gray600,
    dividerFooter: volvoColors.gray100,
    surfaceFooter: volvoColors.gray50,

    radioCheckedBackground: volvoColors.white,
    radioCheckedForeground: volvoColors.primary600,

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#4B5563',

    progressBackgroundColor: '#9CA3AF',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'volvo-subscription': volvoSubscription as string,
  },
  promoImages: {
    XC40BEV: {
      foreground: xc40bevForeground as string,
    },
  },
  containerStyle: {
    default: {},
    raised: {
      backgroundColor: volvoColors.white,
      borderRadius: 1,
      borderStyle: 'solid',
      borderColor: 'border',
      borderWidth: '1px',
    },
    prominent: {},
  },
  // Components
  Logo: {
    default: {
      px: ['', ''],
      height: ['7px', '0.5rem'],
    },
  },
  Button: {
    base: {
      textTransform: 'uppercase',
      transition: 'all 300ms ease-out',
    },
    layout: {
      default: {
        px: 6,
        py: 3,
        fontSize: '14px',
        lineHeight: '24px',
      },
      fixed: {
        px: 6,
        py: 3,
        fontSize: '14px',
        lineHeight: '24px',
        width: '85%',
        maxWidth: '262px',
      },
      small: {
        px: 4,
        py: 2,
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
  },
  PrimaryButton: {
    disabled: {
      default: {
        opacity: '0.32',
      },
    },
    variants: {
      default: {
        bg: volvoColors.primary600,
        color: volvoColors.white,
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: volvoColors.gray900,
        },
      },
      inverted: {
        bg: volvoColors.white,
        color: volvoColors.gray900,
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: 'rgba(255,255,255,0.36)',
        },
      },
      critical: {
        bg: volvoColors.alert600,
        color: volvoColors.alert50,
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: volvoColors.gray900,
        },
      },
    },
  },
  SecondaryButton: {
    disabled: {
      default: {
        opacity: '0.32',
      },
    },
    variants: {
      default: {
        boxShadow: `inset 0 0 0 1px ${volvoColors.primary600}`,
        color: volvoColors.primary600,
        '&:hover:not([disabled])': {
          bg: volvoColors.primary600,
          color: volvoColors.white,
        },
      },
      inverted: {
        boxShadow: `inset 0 0 0 1px ${volvoColors.white}`,
        color: volvoColors.white,
        '&:hover:not([disabled])': {
          bg: volvoColors.white,
          color: volvoColors.gray900,
        },
      },
    },
  },
  TertiaryButton: {
    disabled: {
      default: {
        opacity: '0.32',
      },
    },
    variants: {
      default: {
        boxShadow: `inset 0 0 0 1px ${volvoColors.gray400}`,
        color: volvoColors.gray900,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${volvoColors.gray900}`,
        },
      },
      inverted: {
        boxShadow: `inset 0 0 0 1px ${volvoColors.white}`,
        color: volvoColors.white,
        '&:hover:not([disabled])': {
          bg: volvoColors.white,
          color: volvoColors.gray900,
        },
      },
    },
  },
  IconButton: {
    disabled: {
      default: {
        opacity: '0.32',
      },
    },
    variants: {
      default: {
        '&:hover:not([disabled])': {
          bg: volvoColors.gray50,
        },
      },
      primary: {
        bg: volvoColors.primary600,
        [Icon]: {
          'path, ellipse': {
            fill: '#FFF',
          },
        },
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: volvoColors.gray900,
        },
      },
    },
  },
  Heading: {
    default: {},
    h1: {},
    h2: {},
    h3: {},
    h4: {
      fontWeight: 'normal',
    },
  },
  TabBar: {
    default: {
      borderBottomWidth: 0,
    },
  },
  TabAnchor: {
    active: {
      py: 2,
      color: volvoColors.gray900,
      borderColor: volvoColors.primary600,
      borderBottomWidth: 2,
    },
    inactive: {
      py: 2,
      color: volvoColors.gray600,
      borderColor: 'transparent',
      borderBottomWidth: 2,
      '&:hover': {
        color: volvoColors.primary600,
      },
    },
  },
  LinkAnchor: {
    inline: {
      '&:hover': {
        color: volvoColors.primary600,
        borderColor: volvoColors.primary600,
      },
    },
    standalone: {
      textTransform: 'uppercase',
      color: volvoColors.primary600,
      borderBottomWidth: 0,
      '&:hover': {
        color: volvoColors.gray900,
      },
    },
  },
  FooterAnchor: {
    default: {
      color: volvoColors.gray900,
      '&:hover': {
        color: volvoColors.primary600,
      },
    },
  },
  NavBar: {
    default: {
      pl: [6, 6, 6, 8],
      pr: [4, 4, 6, 8],
    },
  },
  NavAnchor: {
    default: {
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: volvoColors.gray100,
      },
    },
    inverted: {
      fontWeight: 'bold',
    },
  },
  Notification: {
    type: {
      nested: {
        borderRadius: 1,
        borderWidth: 0,
      },
      standalone: {
        borderRadius: 2,
        borderWidth: 0,
      },
    },
  },
  CollapsibleSectionContent: {
    default: {
      px: [0, 0, 0, 0, 0],
      color: 'bodySecondary',
    },
  },
  CollapsibleSectionHeader: {
    default: {
      borderTopWidth: 0,
      [Typo]: {
        fontWeight: 'bold',
      },
      [Icon]: {
        size: '1.5rem',
        minWidth: '1.5rem',
      },
    },
    visible: {
      borderTopWidth: 0,
      [Typo]: {
        fontWeight: 'bold',
      },
      [Icon]: {
        size: '1.5rem',
        minWidth: '1.5rem',
      },
    },
  },
}
